<template>
<div style="background-image: url('/assets/img/image1.png');">
    <div class="projects dirr" style="display: block; padding-top:50px">
        <v-layout row>
            <v-flex sm12>
                <div class="desc2" style="max-width: none;" :style="$vuetify.theme.dark ? 'background-color: #000': 'background-color: #FFF'">
                    <div class="title2">
                        <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                        <a :href="'https://projects.alkafeel.net/?lang='+$i18n.locale" class="tl" :style="!$vuetify.theme.dark ? 'color:#000' : ''">مقالات إخبارية</a> 
                        <div class="spacer"></div>
                        <div class="sec_discrib">يعرض قسم المقالات الأخبارية اخر واحدث التحقيقات الخبرية<br> والقصص وابرز المقالات الفكرية</div>
                    </div>
                    <swiper class="swiper2  dirr" ref="mySwiper" :options="swiperOptions2">
                        <swiper-slide v-for="s in 4"> 
                            <div class="fill-heigh articles_cat" :style="$vuetify.theme.dark ? 'background: #393535' : 'background: #F5F5F5'">
                                <div class="art_cat_icon">
                                    <img src="/assets/img/open-book 1.png">
                                </div>
                                <div style="display: inline-block;">انفوكراف</div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    
                </div>
            </v-flex>
        </v-layout>
        <div>
            <v-layout row>

                <v-flex lg4 v-for="d in 3">
                    <v-card :loading="loading" class="mx-auto my-12 pa-2"  max-width="374" >
                        <v-img height="250" src="https://alkafeel.net/news/mcroped96/625/6e50ffa38faf18101b48412f69b97078.jpg" ></v-img>
                        <v-card-title>زراعة صحراء كربلاء </v-card-title>
                        <v-card-text>
                            كرّم المتولّي الشرعيّ للعتبة العبّاسية المقدّسة السيد أحمد الصافي، أحد 
                        </v-card-text>
                        <v-divider class="mx-4"></v-divider>
                        <v-card-actions>
                            <div class="ml-auto">
                                <div>مشاهدة المشروع</div>
                            </div>
                            <div class="mr-auto">
                                <v-col cols="auto">
                                <v-btn text style="background: #7E7E7E;">
                                    <img src="/assets/img/arrow-left.svg">
                                </v-btn>
                            </v-col>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-flex>

            </v-layout>
        </div>
    </div>
</div>
</template>



<script>


export default {
    name: 'carrousel',
    data() {
        return {
            res:[],
            cats:[],
            swiperOptions2: {
                slidesPerView: "auto",
                spaceBetween:10,
                loop:false,
                freeMode: true,
                keyboard: true,
                slidesPerView: "3",
                height: 87,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            },
        }
    },
    
    computed: {
        swiper() {return this.$refs.mySwiper.$swiper}
    },
    mounted() {
    },
    created () {
    },
       
    methods:{
        
    },
}
</script> 

<style scoped>
    @font-face {
        font-family: 'readex';
        src: url("/assets/fonts/ReadexPro/ReadexPro-Regular.ttf") format("truetype");
    }
    @font-face {
        font-family: 'readex_bold';
        src: url("/assets/fonts/ReadexPro/ReadexPro-Bold.ttf") format("truetype");
    }
    .desc2 {
        box-shadow: 0px 4px 4px 0px #00000014;
        background-color: white;
        border-radius: 8px;
        padding: 15px;
    }
    .sec_discrib {
        font-family: 'readex';
        margin: 0 15px;
    }
    .title2 {
        display:ruby
    }
    .title2 .tl {
        font-family: 'readex_bold';
        font-size: 42px;
        /* color:#000; */
        padding: 5px 15px;
        text-decoration: none;
    }
    .spacer {
        width: 4px;
        height: 30px;
        margin: 0 15px;
        background: #B1BD52;
    }
    .art_cat_icon {
        width: 56px;
        height: 56px;
        background: #adadad;
        border-radius: 50%;
        text-align: center;
        display: inline-block;
        margin-left: 20px;
        margin-right: 20px;
        vertical-align: middle;
    }
    .art_cat_icon img {
        margin-top: 15px;
    }
    .articles_cat {
        background: #F5F5F5;
        width: 100%;
        height: 87px;
        
        align-content: center;
        text-align: start;
        padding-inline-start: 20px;
    }
    .articles_cat div {
        font-family: 'readex';
    }
    .swiper-pagination-bullet-active {
        background: #000!important;
    }
    .pro_img {
        margin: 15% auto 5px;
        width: 56px;
    }
    .pro_cat_text {
        text-decoration: unset;
        font-family: 'readex';
        color: #000;
    }
    .pro_cat {
        min-height: 170px;
        vertical-align: middle;
        text-align: center;
        border: #ddd solid thin;
        box-shadow: 22px 40px 40px 0px #0000000D;
        margin: 10px;
        min-width: 200px;
        background-color: #FFF;
    }
    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-slide img {
        max-height: 210px;
    }
    .height {
        width: 100%;
        height: 100%;
    }
    .gradiant_img {
        background-image: linear-gradient(to top, #0C0F12 0%, transparent 82px);
    }
    .sw_img_date {
        color: white;
        font-family: 'readex';
        font-size: 14px;
        text-decoration: none;
        position: absolute;
        bottom: 8px;
        left: 15px;
    }
    .sw_img_cat {
        color: black;
        font-family: 'readex';
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        bottom: 6px;
        background: #FFF;
        padding: 5px;
        margin-left: 6px;
        margin-right: 6px;
        border-radius: 3px;

    }
    .sw_img_title {
        color: white;
        font-family: 'readex';
        font-size: 20px;
        font-weight: 400;
        position: absolute;
        bottom: 50px;
        left:0;
        right:0;
        text-align: center;
        text-shadow: 1px 1px 2px #000;
    }
    .sw_item {
        vertical-align: bottom;
        border-radius: 5px;
    }
</style>